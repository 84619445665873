import { Button, Grid } from '@mui/material'
import { Wallets } from '../blockchain/Utils'

const MetaMaskButton = ({ onClick, title }: { onClick: (event:any) => Promise<void> | void, title?: string }) => {
    return (
        <Grid mt={2} container alignItems="center">
            <Grid item xs={12}>
                <Button
                    fullWidth
                    onClick={onClick}
                    startIcon={Wallets.metamask.smallIcon}
                    key={'metamask'}
                    variant="contained"
                    color="secondary"
                >
                    {title ?? `Connect ${Wallets.metamask.simpleName}`}
                </Button>
            </Grid> 
        </Grid>
    )
}

export default MetaMaskButton
