import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Grid,
    Link,
    Stack,
    Typography,
} from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Blockchains } from '../../components/blockchain/Utils'
import CardUsage from '../../components/card/card-usage'
import DelegateOptions from '../../components/delegate/DelegateOptions'
import WalletBalance from '../../components/wallet/WalletBalance'
import AccountSwitch from '../../components/account/AccountSwitch'
import { formatBalance } from '@baanx/common/utils'
import CustomWidthTooltip from '../../components/tooltip/CustomTooltip'
import DelegateInfo from '../../components/delegate/DelegateInfo'
import { SupportedToken } from '@baanx/blockchain-config'

interface IFoxDelegateProps {
    coinHandler: () => void
    accountId: string
    selectedCurrency: string
    selectedNetwork: string
    tokenBalance: string
    fiatBalance: string
    delegate: (amount: string) => void
    isContinuous: boolean
    setContinuous: (continuous: boolean) => void
    amount: string
    setAmount: (amount: string) => void
    preferredFiatCurrency: string | undefined
    cardUsageTitle: string | undefined
    delegationProcessing: boolean
    delegationSuccess: boolean
    switchAccountHandler: () => Promise<void>
    allowance: string
    approveTitle: string
}
const FoxDelegate = ({
    coinHandler,
    selectedCurrency,
    selectedNetwork,
    tokenBalance,
    fiatBalance,
    delegate,
    setContinuous,
    isContinuous = true,
    amount,
    setAmount,
    preferredFiatCurrency,
    cardUsageTitle,
    delegationProcessing,
    delegationSuccess,
    accountId,
    switchAccountHandler,
    allowance,
    approveTitle
}: IFoxDelegateProps) => (
    <Grid container>
        <Grid
            ml={0}
            item
            container
            spacing={1}
            rowGap={'1.5rem'}
        >
            <Grid container spacing={1} item xs={12}>
                <Grid item container xs={12} justifyContent="center">
                    <Avatar
                        sx={{
                            backgroundColor: 'black',
                            width: 50,
                            height: 50,
                        }}
                    >
                        {Blockchains[selectedNetwork]?.smallIcon}
                    </Avatar>
                </Grid>
                <Grid item xs={12}>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography align="center" variant="caption">
                            Only available on{' '}
                            {Blockchains[selectedNetwork].name}
                        </Typography>
                        <CustomWidthTooltip
                            placement="left"
                            title={
                                <Stack spacing={1}>
                                    <Typography fontWeight="bold">
                                        Only available on Linea
                                    </Typography>
                                    <Typography>
                                        You'll need a small amount of Linea ETH
                                        to manage your spending limit. You'll
                                        also need tokens like USDC or USDT on
                                        Linea to use your card.
                                    </Typography>
                                </Stack>
                            }
                            arrow
                        >
                            <InfoOutlinedIcon
                                fontSize="small"
                                color="primary"
                            />
                        </CustomWidthTooltip>
                    </Stack>
                </Grid>
            </Grid>
     {(
                !delegationProcessing &&
                !delegationSuccess && (
                    <Grid rowGap={'1.5rem'} container>
                        <Grid item xs={12}>
                            <AccountSwitch
                                switchAccountHandler={switchAccountHandler}
                                connectedAccount={accountId}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <WalletBalance
                                preferredFiatCurrency={
                                    preferredFiatCurrency ?? ''
                                }
                                selectedCurrency={selectedCurrency}
                                tokenBalance={tokenBalance}
                                coinHandler={coinHandler}
                                fiatBalance={fiatBalance}
                                allowance={allowance}
                                isWarning={
                                    selectedCurrency === SupportedToken.WETH
                                }
                            />
                            {selectedCurrency === SupportedToken.WETH && (
                                <Grid item xs={12}>
                                    <Box mt="8px">
                                        <Typography fontSize="0.725rem" align="center">
                                            There&apos;s a swap fee for each
                                            transaction when using WETH.{' '}
                                            <Link  target="_blank" underline="none" href={'https://support.metamask.io/metamask-portfolio/card/using/#transaction-fees'}>Learn more</Link>
                                        </Typography>
                                    </Box>
                                </Grid>
                            )}
                        </Grid>

                        {cardUsageTitle && !isContinuous && (
                            <Grid item xs={12}>
                                <CardUsage cardUsageTitle={cardUsageTitle} />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography
                                fontWeight={700}
                                align="center"
                                variant="body1"
                            >
                                Select to continue
                            </Typography>
                        </Grid>
                        <DelegateOptions
                            isContinuousApproval={isContinuous}
                            onContinuousSelected={setContinuous}
                            onChangeAmount={setAmount}
                            currency={selectedCurrency}
                        />
                        <Grid
                            item
                            alignItems="center"
                            justifyItems="center"
                            textAlign={'center'}
                            xs={12}
                        >
                            <Button
                                fullWidth
                                onClick={delegate.bind(null, amount)}
                                variant="contained"
                            >
                                <Typography variant="h6">
                                    {approveTitle}
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                )
            )}
            { (delegationProcessing || delegationSuccess) && <Grid container item xs={12}>
                {
                    <>
                        {' '}
                        <Grid item xs={12}>
                            {delegationProcessing && !delegationSuccess && (
                                <>
                                    <Box textAlign="center" pb={"1.5rem"}>
                                        <Typography fontWeight={700} variant="body1">Account</Typography> 
                                        <Typography variant="caption">{`${accountId.substring(0,6)}...${accountId.slice(-6)}`}</Typography>
                                    </Box>
                                    <DelegateInfo
                                        title={
                                            isContinuous
                                                ? 'Automatically approve spending'
                                                : 'Approve a specific limit'
                                        }
                                        amount={
                                            isContinuous
                                                ? 'No spending limit'
                                                : `${formatBalance(
                                                      amount
                                                  )} ${selectedCurrency.toUpperCase()}`
                                        }
                                        description={
                                            "You'll need to approve the spending cap request in your MetaMask wallet."
                                        }
                                    />
                               <Grid item container xs={12}
                                        mt={2}
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid item xs={2}>
                                        <CircularProgress size="2rem" />
                                        </Grid>
                                        <Grid item xs={8}><Typography align="center" variant="h5" >
                                            Confirming in MetaMask
                                        </Typography></Grid>
                                       <Grid item xs={2}/>
                                    </Grid>
                                </>
                            )}
                            {!delegationProcessing && delegationSuccess && (
                                <Stack
                                    mt={2}
                                    justifyContent="center"
                                    alignItems="center"
                                    direction="row"
                                    spacing={1}
                                >
                                    <CheckCircleOutlineIcon
                                        color="success"
                                        fontSize="large"
                                    />
                                    <Typography variant="h5">
                                        Approval successful
                                    </Typography>
                                </Stack>
                            )}
                        </Grid>
                    </>
                }
            </Grid>}
        </Grid>
    </Grid>
)

export default FoxDelegate
