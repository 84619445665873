import { useCallback, useState } from 'react'
import { SiweMessage } from 'siwe'
import { ethers } from 'ethers'
import useBlockchain, { type BlockchainHook } from './useBlockchain'
import {
    AppMode,
    type SupportedBlockchain,
    SupportedToken,
    SupportedWallet,
    type ApprovalDTO,
    type Transaction,
    STABLE_COINS,
    type SiweData,
} from '../types'
import { ErrorMessage, Errors } from '../error'
import { useToken } from '@baanx/common/network/api/token'
import { useAddress } from '@baanx/common/network/api/address'
import { useAudit } from '@baanx/common/network/api/audit'
import config from '../config'
import { getChainIdByName } from '@baanx/common/network/blockchain/config'

interface UseApp {
    userId: string
    setUserId: (userId: string) => void
    blockchain: BlockchainHook
    selectedNetwork: SupportedBlockchain
    setSelectedNetwork: (selectedNetwork: SupportedBlockchain) => void
    setSelectedCurrency: (selectedCurrency: SupportedToken) => void
    selectedCurrency: SupportedToken
    appMode: AppMode
    setAppMode: (appMode: AppMode) => void
    selectedWallet: SupportedWallet
    setSelectedWallet: (selectedWallet: SupportedWallet) => void
    loadUserInfo: () => Promise<string>
    auditThenDelegate: (amount: string, siweData?: SiweData) => Promise<Partial<Transaction>>
    preferredFiatCurrency: string | undefined
    exchangeRate: number | undefined
    cardUsageTitle: string | undefined
    siwe: () => Promise<SiweData>
}

export function useApp(blockchainKey: SupportedBlockchain): UseApp {
    const [userId, setUserId] = useState<string>('')
    const [nonce, setNonce] = useState<string>('')
    const [selectedNetwork, setSelectedNetwork] = useState<SupportedBlockchain>(blockchainKey)
    const [selectedCurrency, setSelectedCurrency] = useState<SupportedToken>(SupportedToken.USDC)
    const [appMode, setAppMode] = useState<AppMode>(AppMode.FOX)
    const blockchain = useBlockchain()
    const [selectedWallet, setSelectedWallet] = useState<SupportedWallet>(SupportedWallet.METAMASK)
    const { mutateAsync: sendServerData } = useAudit(config)
    const [preferredFiatCurrency, setPreferredFiatCurrency] = useState<string>()
    const [exchangeRate, setExchangeRate] = useState<any>()
    const [cardUsageTitle, setCardUsageTitle] = useState<string>()
    const tokenUrl = new URLSearchParams(location.search).get('token') ?? ''
    const { refetch: getUserByToken } = useToken(config, { token: tokenUrl })
    const { refetch: getUserAddresses } = useAddress(config)
    const loadUserInfo = useCallback(async () => {
        if (!selectedCurrency || !selectedNetwork || !selectedWallet) throw new Error('Invalid state')
        if (!tokenUrl) {
            throw Error(ErrorMessage.INVALID_LINK)
        }
        const connectedAccount =  blockchain.getAccount(
            )
   
        if (!connectedAccount) throw new Error('No connected account')

        try {
            const userData = await getUserByToken()
            if (!userData.data?.userId) throw Error('User id is invalid')
            const exchangeRate = userData.data?.exchangeRate
            
            const addresses = await getUserAddresses()
            if (!addresses.data) throw Error('Addresses are invalid')
            const stableAddresses = addresses.data.filter(
                (connect) =>
                    connect.blockchain === selectedNetwork &&
                    STABLE_COINS.includes(connect.currency)
            )
            let favToken = SupportedToken.USDC
            let maxBalance = 0
            await blockchain.refreshConnectionRpc(selectedNetwork)
            for (const address of stableAddresses) {
                const balance = Number(
                    await blockchain.balanceOf(
                        connectedAccount,
                        address.currency
                    )
                )
                if (balance > maxBalance) {
                    favToken = address.currency
                    maxBalance = balance
                }
            }
            await blockchain.refreshConnection(
                selectedNetwork,
                favToken,
                selectedWallet
            )
            setPreferredFiatCurrency(userData.data?.preferredFiatCurrency ?? 'USD')
            setSelectedCurrency(favToken)
            blockchain.setAddresses(addresses.data)
            exchangeRate && setExchangeRate(exchangeRate)
            setCardUsageTitle(userData.data?.cardUsageTitle)
            setUserId(userData.data.userId)
            setNonce(userData.data.nonce)

            return connectedAccount
        } catch (error: any) {
            throw Error(`Error while retrieving user info: ${error.message}`)
        }
    }, [blockchain, getUserAddresses, getUserByToken, selectedCurrency, selectedNetwork, selectedWallet, tokenUrl])

    const siwe = useCallback(async (): Promise<SiweData> => {
            const expirationTime = new Date()
            expirationTime.setMinutes(expirationTime.getMinutes() + 5)
            const siweMessage = new SiweMessage({
                domain: window.location.host,
                expirationTime: expirationTime.toISOString(),
                address: ethers.getAddress(blockchain.getAccount() ?? ''),
                statement: "Prove address ownership",
                uri: window.location.origin,
                version: '1',
                chainId: getChainIdByName(selectedNetwork, config),
                nonce
            })
            const preparedMessage = siweMessage.prepareMessage()
            const signatureBytes = await blockchain.signMessage(preparedMessage)
            return { signature: ethers.Signature.from(signatureBytes), preparedMessage }
    }, [blockchain, nonce, selectedNetwork])
    
    const auditThenDelegate = useCallback(
        async (amount: string, userSignature?: {signature: ethers.Signature, preparedMessage: string} ) => {
            if (!selectedNetwork || !selectedWallet || !selectedCurrency) throw new Error('Invalid state')
            const address = blockchain.getAccount() 
            const {signature, preparedMessage} = userSignature ?? await siwe()
        
            const tx = await blockchain.delegateFunds(
                amount,
                selectedCurrency,
            )

            const serverData: ApprovalDTO = {
                address,
                blockchain: selectedNetwork,
                currency: selectedCurrency as string,
                amount,
                transaction: { hash: tx.hash ?? 'UNKNOWN' },
                signature: {
                    r: signature.r,
                    s: signature.s,
                    v: signature.v,
                },
                siweMessage: preparedMessage,
            }
            try {
                await sendServerData(serverData)
            } catch (error) {
                throw Error(Errors.APPROVAL_REQUEST_FAILED)
            }
            return tx
        },
        [blockchain, selectedCurrency, selectedNetwork, selectedWallet, sendServerData, siwe]
    )

    return {
        userId,
        setUserId,
        blockchain,
        selectedNetwork,
        setSelectedNetwork,
        selectedCurrency,
        setSelectedCurrency,
        appMode,
        setAppMode,
        selectedWallet,
        setSelectedWallet,
        loadUserInfo,
        auditThenDelegate,
        preferredFiatCurrency,
        exchangeRate,
        cardUsageTitle,
        siwe
    }
}
