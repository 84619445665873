import { Box, Button, Grid, Typography } from '@mui/material'
import { type ReactElement, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Blockchains, Currencies } from '../../components/blockchain/Utils'
import Loading from '../../components/loading/Loading'
import ConfirmModal from '../../components/modals/ConfirmModal'
import config from '../../config'
import { ResultModalType, useModal } from '../../contexts/ModalContext'
import {
    PostErrorMessage,
    PostOperationType,
    getErrorMessage,
    handleError,
} from '../../error'
import { useAddress } from '@baanx/common/network/api/address'
import { useAudit } from '@baanx/common/network/api/audit'
import { useDeleteWalletToken } from '@baanx/common/network/api/token'
import {
    type ApprovalDTO,
    NavigateLocations,
    type SupportedBlockchain,
    SupportedWallet,
    type SupportedToken,
} from '../../types'
import { formatBalance, ALLOWANCE_MAX_VALUE } from '../../utils'
import usePostMessage from '../../hooks/usePostMessage'
import { useSDK } from '@metamask/sdk-react'
import { isMobile } from 'react-device-detect'
import MetaMaskButton from '../../components/metamask/MetaMaskButton'
import { type BlockchainHook } from '../../hooks/useBlockchain'

let strictInit = false

interface DelegateSwapProps {
    selectedCurrency: SupportedToken
    selectedWallet: SupportedWallet
    selectedNetwork: SupportedBlockchain
    blockchain: BlockchainHook
    address: string
    userId: string
}
const DelegateSwap = ({
    selectedCurrency,
    selectedWallet,
    selectedNetwork,
    blockchain,
    address,
    userId,
}: DelegateSwapProps): ReactElement => {
    const [control, setControl] = useState(false)
    const { sdk, ready, provider } = useSDK()
    const { refreshConnection, tokenBalance, delegateFunds, getAccount } =
        blockchain
    const { toggleWallet, toggleResult, toggleProcessing } = useModal()
    const [isLoading, setIsLoading] = useState(true)
    const [openModal, setOpenModal] = useState(false)
    const [modalDescription, setModalDescription] = useState('')
    const { mutateAsync: sendServerData } = useAudit(config, {
        activateAddress: false,
    })
    const { refetch: getUserAddresses } = useAddress(config)

    const { postSuccessMessage, postErrorMessage } = usePostMessage(
        PostOperationType.DELEGATE_FUNDS
    )
    const { postSuccessMessage: postAbortMessage } = usePostMessage(
        PostOperationType.ABORT
    )

    const { mutateAsync: deleteWalletToken } = useDeleteWalletToken(config)

    const navigate = useNavigate()

    const onConfirmModalHandler = async (): Promise<void> => {
        try {
            await deleteWalletToken({})
        } catch (error) {
            handleError(error)
        }
        navigate(NavigateLocations.CONNECT_USER_WALLET, {
            state: { walletChange: true },
        })
    }

    const onCancelModalHandler = (): void => {
        ;(window as any).top.location.href = `${config.redirectLink}`
    }

    useEffect(() => {
        if (isMobile && selectedWallet === SupportedWallet.METAMASK) {
            strictInit = true
            setIsLoading(false)
            return
        }
        if (
            selectedCurrency == null ||
            selectedNetwork == null ||
            selectedWallet == null ||
            !ready ||
            !provider ||
            !sdk ||
            strictInit ||
            isMobile
        ) {
            return
        }

        void (async () => {
            strictInit = true
            try {
                setIsLoading(true)
                const accountId = await refreshConnection(
                    selectedNetwork,
                    selectedCurrency,
                    selectedWallet,
                )

                if (accountId?.toLowerCase() !== address.toLowerCase()) {
                    setOpenModal(true)
                    setModalDescription(
                        `Please reconnect with the previously selected wallet address ${address}. Would you like to disconnect now?`
                    )
                    return
                }

                // If it is a registered user we check if he has already approved the token with another address
                if (userId) {
                    try {
                        const { data: connectedAddresses } =
                            await getUserAddresses()
                        const otherConnectedAddress = connectedAddresses?.find(
                            (connect) =>
                                connect.address !== accountId.toLowerCase() &&
                                connect.blockchain === selectedNetwork
                        )

                        if (otherConnectedAddress) {
                            setOpenModal(true)
                            setModalDescription(
                                `You have already connected to ${selectedNetwork} with the address ${otherConnectedAddress.address}. Would you like to access the wallet connect menu?`
                            )
                            return
                        }
                    } catch (error) {
                        toggleResult(
                            true,
                            'Error',
                            'Error occurred while getting connected addresses',
                            ResultModalType.ERROR
                        )
                        handleError(error)
                    }
                }
                setIsLoading(false)
            } catch (error) {
                const errorMessage = getErrorMessage(error)
                setIsLoading(false)
                toggleResult(
                    true,
                    'Error',
                    `An error occurred. Reason: ${errorMessage}`,
                    ResultModalType.ERROR
                )
            }
        })()
    }, [
        address,
        getAccount,
        getUserAddresses,
        provider,
        ready,
        refreshConnection,
        sdk,
        selectedCurrency,
        selectedNetwork,
        selectedWallet,
        toggleResult,
        userId,
    ])

    const onConnectHandler = async (): Promise<void> => {
        if (selectedCurrency == null) return

        const amount = ALLOWANCE_MAX_VALUE
        toggleWallet(true, '')

        let tx
        try {
            tx = await delegateFunds(amount, selectedCurrency)
            toggleProcessing(true)
        } catch (error) {
            toggleResult(
                true,
                'Error',
                getErrorMessage(error),
                ResultModalType.ERROR
            )
            return
        }

        const serverData: ApprovalDTO = {
            address,
            blockchain: selectedNetwork,
            currency: selectedCurrency,
            amount,
            transaction: { hash: tx.hash },
        }
        await sendServerData(serverData).catch((error) => {
            toggleResult(
                true,
                'Error',
                'A problem occurred while approving.',
                ResultModalType.ERROR
            )
            throw Error(
                `An error occurred while sending data to server. Error: ${getErrorMessage(
                    error
                )}`
            )
        })

        try {
            await tx.wait()
            postSuccessMessage()
            toggleProcessing(false)
        } catch (error) {
            postErrorMessage(PostErrorMessage.DELEGATE_FUNDS, error)
            toggleProcessing(false)
        }
    }
    const onCancelHandler = (): void => {
        postAbortMessage()
    }

    const connectMobileHandler = async () => {
        try {
            await blockchain.connectMetamask(selectedNetwork)
            await refreshConnection(
                selectedNetwork,
                selectedCurrency,
                selectedWallet,
                false
            )
            setControl(true)
        } catch (error) {
            postErrorMessage(PostErrorMessage.WALLET_CONNECTION_ERROR, error)
            throw error
        }
    }
    return (
        <>
            <ConfirmModal
                open={openModal}
                title="Info"
                cancelLabel="Back"
                description={modalDescription}
                onConfirm={onConfirmModalHandler}
                onCancel={onCancelModalHandler}
            ></ConfirmModal>
            <Grid p={4}>
                <Grid item xs={12}>
                    <Typography align="center" variant="h5">
                        Connect your funds to CL swaps to start swapping today
                    </Typography>
                </Grid>

                <Grid
                    item
                    container
                    xs={12}
                    justifyContent={'center'}
                    marginTop={2}
                    marginBottom={2}
                >
                    <Grid
                        container
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Grid item xs={4} textAlign={'right'}>
                            <Typography variant="h5">
                                {selectedCurrency &&
                                    Currencies[selectedCurrency].name}
                            </Typography>
                        </Grid>

                        <Grid item xs={2} textAlign={'center'}>
                            {selectedNetwork != null &&
                                Blockchains[selectedNetwork].icon}
                        </Grid>

                        <Grid item xs={4} textAlign={'left'}>
                            <Typography variant="h5">
                                {selectedNetwork != null &&
                                    Blockchains[selectedNetwork].name}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                {isLoading && <Loading />}
                {!isLoading && (
                    <>
                        {isMobile &&
                        !control &&
                        selectedWallet === SupportedWallet.METAMASK ? (
                            <>
                                <MetaMaskButton
                                    onClick={connectMobileHandler}
                                ></MetaMaskButton>
                            </>
                        ) : (
                            <>
                                {' '}
                                {/* TODO outline this Box */}
                                <Box>
                                    <Grid container p={2} alignItems={'center'}>
                                        <Grid item xs={6} alignItems={'center'}>
                                            <Grid item xs={12}>
                                                <Typography
                                                    align="left"
                                                    variant="body1"
                                                >
                                                    Wallet balance
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography
                                                    align="left"
                                                    variant="h4"
                                                >
                                                    {tokenBalance &&
                                                        formatBalance(
                                                            tokenBalance
                                                        )}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={6}
                                            textAlign={'right'}
                                            paddingTop={1}
                                        >
                                            {selectedCurrency != null &&
                                                Currencies[selectedCurrency]
                                                    .icon}
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Grid container marginTop={4} columnSpacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="secondary"
                                            onClick={onCancelHandler}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={onConnectHandler}
                                        >
                                            Connect
                                        </Button>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </>
                )}
            </Grid>
        </>
    )
}

export default DelegateSwap
