import getConfig, {
    getBlockchainService,
    switchOrAddNetwork
} from '@baanx/common/network/blockchain/config'
import { useSDK } from '@metamask/sdk-react'
import { ethers } from 'ethers'
import { useCallback, useState } from 'react'
import cfg from '../config'
import {
    type SupportedBlockchain,
    type BlockchainService,
    type ConnectAddress,
    type SupportedToken,
    type SupportedWallet,
    type Transaction,
} from '../types'
import { isMobile } from 'react-device-detect'

export interface BlockchainHook {
    refreshConnectionRpc: (network: SupportedBlockchain) => Promise<void>
    refreshConnection: (
        network: SupportedBlockchain,
        currency: SupportedToken,
        selectedWallet: SupportedWallet,
        isPayment?: boolean
    ) => Promise<string>
    getAccount: (
    ) => string
    delegateFunds: (
        amount: string | undefined,
        tokenType: SupportedToken
    ) => Promise<Partial<Transaction>>
    getAllowance: (
        address: string,
        tokenType: SupportedToken
    ) => Promise<string>
    tokenBalance: string
    isConnected: boolean
    disconnect: () => void
    isLoading: boolean
    setAddresses: (addresses: ConnectAddress[]) => void
    addresses: ConnectAddress[]
    payment: (amount: string, unit: any) => Promise<any>
    balanceOf: (
        address: string,
        tokenType: SupportedToken
    ) => Promise<string> | undefined
    getBalance: (address: string) => Promise<string> | undefined
    signMessage: (message: string) => Promise<string>
    signTypedData: (domain, types, message) => Promise<ethers.SignatureLike>
    getDecimals: (tokenAddress: SupportedToken) => number
    connectMetamask: (selectedBlockchain: SupportedBlockchain | undefined) => Promise<void>
    switchAccount: () => Promise<void>
    allowance: string
}
let blockchainProvider: BlockchainService
let blockchainProviderRpc: BlockchainService

const rpcProvider: Record<SupportedBlockchain, ethers.Provider> = {} as any
const useBlockchain = (): BlockchainHook => {
    const { sdk, account, connected: isConnected, provider } = useSDK()
    const [tokenBalance, setTokenBalance] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [addresses, setAddresses] = useState<ConnectAddress[]>([])
    const [allowance, setAllowance] = useState<string>('0')
    const disconnect = useCallback(() => {
        setTokenBalance('')
        setAddresses([])
    }, [])

    const connectMetamask = useCallback(
        async (selectedChain?: SupportedBlockchain) => {
            if (!selectedChain) throw Error('Selected chain is undefined')
            try {
        console.log("before...")
                await sdk?.connect()
            } catch (error: any) {
                if (error?.message?.match(/already processing/i)) {
                    await sdk?.getProvider?.()?.request({
                        method: 'wallet_requestPermissions',
                        params: [{ eth_accounts: {} }],
                      })
                }
            }
        },
        [sdk]
    )
    const refreshConnectionRpc = useCallback(
        async (networkParam: SupportedBlockchain) => {
            try {
                setIsLoading(true)
                const config = getConfig(networkParam, cfg)

                if (!rpcProvider[networkParam]) {
                    // TODO WARNING refactor this HACK
                    if (config.jsonRpcUrl.startsWith('wss')) {
                        rpcProvider[networkParam] =
                            new ethers.WebSocketProvider(config.jsonRpcUrl)
                    } else {
                        rpcProvider[networkParam] = new ethers.JsonRpcProvider(
                            config.jsonRpcUrl
                        )
                    }
                }
                blockchainProviderRpc = await getBlockchainService(
                    networkParam,
                    rpcProvider[networkParam],
                    false,
                    cfg
                )
            } finally {
                setIsLoading(false)
            }
        },
        []
    )

    const refreshConnection = useCallback(
        async (
            networkParam: SupportedBlockchain,
            currencyParam: SupportedToken,
            _selectedWallet: SupportedWallet,
            isPayment = false
        ) => {
            try {
                setIsLoading(true)
                // Init wallet Provider
                const userAccount = account ?? sdk?.getProvider()?.getSelectedAddress()
                if (!userAccount) throw Error("Not connected")
              
             /*    walletProvider.setOnChangeHandler(async () => {
                    if (!isMobile) {
                        await sdk?.terminate()
                        window.location.reload()
                       }
                }) */
               // We can't switch networks on mobile without a click, so we ignore it here. It is for the desktop version
                if (!isMobile) {
                    await switchOrAddNetwork(networkParam, sdk?.getProvider(), cfg)
                }
                blockchainProvider = await getBlockchainService(
                    networkParam,
                    sdk?.getProvider(),
                    false,
                    cfg
                )

                if (blockchainProvider.validateCurrency) {
                    const isValid = await blockchainProvider.validateCurrency(
                        currencyParam
                    )
                    if (!isValid) {
                        throw new Error(
                            `Your account does not support ${currencyParam}. Create a ${currencyParam} token account before using Solana.`
                        )
                    }
                }

                if (!isPayment) {
                    const tokenBalance = await blockchainProvider.balanceOf(
                        userAccount,
                        currencyParam
                    )
                    tokenBalance && setTokenBalance(tokenBalance)
                    const allowance = await blockchainProvider.getAllowance(userAccount, currencyParam)
                    setAllowance(allowance)
                }
                return userAccount
            } finally {
                setIsLoading(false)
            }
        },
        [account, sdk]
    )
    const delegateFunds = useCallback(
        async (amount: string | undefined, tokenType: SupportedToken) => {
            if (!amount) throw Error('Amount cannot be undefined!')
    
            if (!blockchainProvider) throw Error('Blockchain provider not initialized')
            if (!blockchainProvider.buildApproveTransaction) throw Error('buildApproveTransaction not implemented')
            // In order to overcome mobile restrictions we had to use the provider directly, without using ethers to send it. 
            // Using the blockchain provider approve, makes more than one 1 call to the Wallet, which makes the deeplink to fail.
            const transactionParameters = await blockchainProvider.buildApproveTransaction?.(amount, tokenType)
            const hash = await provider?.request<string>({
                method: 'eth_sendTransaction',
                params: [{
                    ...transactionParameters,
                    gasLimit: transactionParameters?.gasLimit?.toString(16),
                    from: account,
                  }],
            })
            if (!hash) throw new Error("Transaction failed")
            await blockchainProvider.waitForTransaction(hash)
            return { hash }
        },
        [account, provider]
    )

    const payment = useCallback(async (amount: string, unit: any) => {
        return await blockchainProvider.payment(amount, unit)
    },[])
    const getAllowance = useCallback(
        async (a: string, t: SupportedToken) =>
            await blockchainProviderRpc?.getAllowance(a, t),
        []
    )
    const balanceOf = useCallback(
        async (a: string, t: SupportedToken): Promise<string> =>
            await blockchainProviderRpc?.balanceOf(a, t)?.catch(() => {
                // Handle when the account does not have a solana token account.
                return '0.0'
            }),
        []
    )
    const getBalance = useCallback(
        async (a: string) => await blockchainProviderRpc?.getBalance(a),
        []
    )

    const getDecimals = useCallback(
         (tokenAddress: SupportedToken) => blockchainProviderRpc.decimals(tokenAddress) 
        ,
        []
    )
    const getAccount = useCallback(
         (
        ) => {
           return (account ?? sdk?.getProvider()?.getSelectedAddress()) ?? ''
        },
        [account, sdk]
    )

    const signMessage = useCallback(
        async (message: string): Promise<string> =>
            await blockchainProvider?.signMessage(message),
        []
    )
    const signTypedData = useCallback(
        async (domain, types, message): Promise<ethers.SignatureLike> => {
            return await blockchainProvider.signTypedData(domain, types, message)
        },
        []
    )

    const switchAccount = async () => {
        const accounts = await sdk?.getProvider()?.request({
            method: 'wallet_requestPermissions',
            params: [
                {
                    eth_accounts: {},
                },
            ],
        })
        return accounts?.[0]
    }

    return {
        connectMetamask,
        refreshConnectionRpc,
        refreshConnection,
        getAccount,
        isConnected,
        delegateFunds,
        tokenBalance,
        disconnect,
        isLoading,
        addresses,
        setAddresses,
        payment,
        getAllowance,
        balanceOf,
        getDecimals,
        getBalance,
        signMessage,
        signTypedData,
        switchAccount, 
        allowance
    }
}

export default useBlockchain
