import {
    SUPPORTED_BLOCKCHAINS,
    SUPPORTED_SWAP_BLOCKCHAINS,
    SupportedBlockchain,
    type SupportedToken,
    type SupportedWallet,
} from '@baanx/blockchain-config'
import { type ContractTransaction, type ethers } from 'ethers'

// Type for getUserAddresses endpoint response
export interface ConnectAddress {
    id: string
    blockchain: SupportedBlockchain
    address: string
    currency: SupportedToken
    priority: number
}

export interface WalletData {
    wallet: {
        provider: SupportedWallet
        address: string
        index?: number
    }
}

export type UserData = {
    userId: string
    nonce: string
    token: string
    cardUsageTitle?: string
    preferredFiatCurrency?: string
    exchangeRate?: Record<string, Record<string, string>>
} & { [key in SupportedBlockchain]: boolean }

export interface Transaction {
    hash: string
    blockNumber?: number
    status?: TransactionStatus
    wait?: () => Promise<any>
}

enum TransactionStatus {
    OK = 'OK',
    KO = 'KO',
}

export interface BlockchainConfig {
    blockchain: SupportedBlockchain
    beneficiaryAddress: string
    paymentAddress: string
    contractAddress: string
    operatorAddress: string
    operatorKey: string
    jsonRpcUrl: string
    chainId: string
    token: {
        [key in SupportedToken]: {
            address: string
        }
    }
}
export interface BlockchainService {
    waitForTransaction: (hash: string) => Promise<void>
    getBalance: (address: string) => Promise<string>
    getAllowance: (
        address: string,
        tokenType: SupportedToken
    ) => Promise<string>
    approve: (
        amount: string,
        tokenType: SupportedToken
    ) => Promise<Partial<Transaction>>
    buildApproveTransaction?: (
        amount: string,
        tokenType: SupportedToken
    ) => Promise<ContractTransaction>
    balanceOf: (address: string, tokenType: SupportedToken) => Promise<string>
    validateCurrency?: (tokenType: SupportedToken) => Promise<boolean>
    decimals: (tokenType: SupportedToken) => number
    payment: (
        amount: string,
        unit: SupportedToken
    ) => Promise<Transaction>
    signMessage: (message: string) => Promise<string>
    signTypedData: (domain, types, message) => Promise<ethers.SignatureLike>
}

// TODO: we can share this between be and fe (and most of the types above Transaction type). Maybe on the shared-types package
export interface PaymentTransactionDto {
    paymentId: string
    address: string
    blockchain: SupportedBlockchain
    currency: SupportedToken
    amount: string,
    transaction: { hash: string }
}

export enum AppMode {
    PAYMENT = 'payment',
    NORMAL = 'normal',
    DIRECT_TOKEN_PRIORITY = 'direct_token_priority',
    CONNECT_WALLET = 'connect_wallet',
    SWAP = 'swap',
    SIGN = 'sign',
    FOX = 'fox',
}

export const SUPPORTED_BLOCKCHAINS_BY_APP_MODE: {
    [key in AppMode]: SupportedBlockchain[]
} = {
    [AppMode.PAYMENT]: SUPPORTED_BLOCKCHAINS.filter((blockchain) => blockchain !== SupportedBlockchain.LINEA_US),
    [AppMode.NORMAL]: SUPPORTED_BLOCKCHAINS,
    [AppMode.DIRECT_TOKEN_PRIORITY]: SUPPORTED_BLOCKCHAINS,
    [AppMode.SWAP]: SUPPORTED_SWAP_BLOCKCHAINS,
    [AppMode.CONNECT_WALLET]: SUPPORTED_SWAP_BLOCKCHAINS,
    [AppMode.SIGN]: SUPPORTED_SWAP_BLOCKCHAINS,
    [AppMode.FOX]: [SupportedBlockchain.ETHEREUM],
}

export interface Config {
    environment: string,
    blockchainConfigKey: string,
    domain: string,
    redirectLink: string,
    app: {
        port: number,
        https: boolean
    },
    api: {
        url: string
    },
    sentry: {
        dsn: string,
        environment: string,
        tracesSampleRate: number
    },
    walletConnectProjectId?: string,
}

export * from '@baanx/blockchain-config'
